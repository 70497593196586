
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  LPopup,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "Heatmaps",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LPopup,
  },
  setup() {
    setCurrentPageTitle("Lokasi Atribusi");

    const store = useStore();
    // const router = useRouter();

    store.dispatch("AllAttributeDashboard");

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const path = window.location.origin + "/";
    const icon = path + "other/png/Group 248.png";

    // const markers = ref([
    //   {
    //     id: 1,
    //     position: [-6.30219, 106.725977],
    //     icon: path + "other/png/Group 248.png",
    //     data: {
    //       keterangan: "Pasang spanduk di tempat A",
    //       tanggal: "30 Agustus 2021",
    //       img: path + "other/png/Rectangle -10.png",
    //       deskripsi: "Keterangan tambahannya ...",
    //       oleh: "Bayu",
    //     },
    //   },
    //   {
    //     id: 2,
    //     position: [-6.303169, 106.724987],
    //     icon: path + "other/png/Group 259.png",
    //     data: {
    //       keterangan: "Pesangan Baliho di tempat B",
    //       tanggal: "8 Januari 2021",
    //       img: path + "other/png/Rectangle -11.png",
    //       deskripsi: "Keterangan tambahannya ...",
    //       oleh: "Bayu",
    //     },
    //   },
    // ]);
    const markers = computed(() => store.state.AttributeDashboardModule.all);
    //

    const info = ref([
      {
        img: "other/png/Group 248.png",
        text: "Spanduk",
      },
      {
        img: "other/png/Group 259.png",
        text: "Baliho",
      },
      {
        img: "other/png/Group 245.png",
        text: "Lain-lain",
      },
    ]);

    return {
      center,
      markers,
      info,
      icon,
      zoom,
      iconSize,
    };
  },
});
